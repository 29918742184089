.table-pagination {
  $root: &;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px auto 0 auto;
  max-width: 375px;

  &__button {
    background-color: transparent;
    border: none;
    touch-action: manipulation;
  }

  &__arrow {
    fill: #7B7C7C;

    &--next {
      transform: rotate(180deg);
    }

    &--disabled{
      fill: #ABACAD;
    }
  }

  &__page{
    color: #989898;
    font-size: rems(14px);
    font-weight: 600;
  }

  &--dark{
    #{$root}__arrow{
      fill: #151618;
    }
  }
}
