.ledger-addresses-modal {
  $root: &;
  min-width: 600px;
  padding: 70px 50px 36px 50px;

  &--dark {
    #{$root}__select {
      background-color: $color-steel-gray;
      border-color: $color-steel-gray;
      color: rgba($color-storm-gray, 0.69);
    }

    #{$root}__row {
      color: $color-shuttle-gray;
      border-color: $color-black-russian;

      &--selected {
        color: $color-white;

        #{$root}__radio{
          background-color: $color-dodger-blue-dark;
        }
      }
    }

    #{$root}__table-wrapper {
      border-color: $color-black-russian;
    }

    #{$root}__loader {
      background-color: rgba($color-bastille, 0.7);
    }

    #{$root}__radio{
      border-color: #212224;
      background-color: #424347;
    }
  }

  &__label {
    font-size: rems(12px);
    font-weight: 700;
    margin-top: 36px;
    margin-bottom: 12px;
    color: $color-shady-lady;
  }

  &__select {
    border: 1px solid #e9ebec;
    border-radius: 4px;
    color: rgba($color-link-water, 0.69);
    font-size: rems(16px);
    font-weight: 600;
    padding: 8px 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-top: 30px;
  }

  &__row {
    cursor: pointer;
    border-top: 1px solid $color-white-smoke-dark;
    &:first-child {
      border: none;
    }

    &--selected {
      color: $color-black;

      #{$root}__radio {
        &:after {
          position: absolute;
          top: 2px;
          left: 2px;
          content: "";
          width: 8px;
          height: 8px;
          background-color: $color-dodger-blue-dark;
          border-radius: 50%;
        }
      }
    }
  }

  &__cell {
    padding: 12px 32px 12px 0;
    font-size: rems(12px);
    font-weight: 700;

    &--text-right{
      text-align: right;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__radio {
    position: relative;
    width: 16px;
    max-width: 16px;
    height: 16px;
    max-height: 16px;
    border-radius: 50%;
    border: 2px solid $color-shady-lady;
  }

  &__external-link {
    color: inherit;

    &:hover{
      color: inherit;
    }

    svg {
      display: block;
      height: auto;
      width: 18px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: $color-nobel;
    font-size: rems(12px);
    font-weight: 700;
    margin-top: 20px;
  }

  &__btn-text {
    padding: 4px;
  }

  &__btn-icon {
    width: auto;
    height: 18px;
  }

  &__loader {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: -1px;
    background-color: rgba($color-white, 0.7);
    z-index: 10;
  }

  &__table-wrapper {
    position: relative;
    min-height: 216px;
    border-top: 1px solid $color-white-smoke-dark;
    border-bottom: 1px solid $color-white-smoke-dark;
  }

  &__actions {
    margin-top: 36px;

    .button--secondary {
      margin-right: 28px;
    }
  }

  &__table {
  }
}
