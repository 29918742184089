.c-dropdown-item {
  display: flex;
  align-items: center;
  height: 62px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($color-white, 0.1);
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease;
    color: $color-white;
    font-weight: 700;
    padding: 0 24px;

    &:link {
      text-decoration: none;
    }

    &:hover {
      color: $color-white;
    }
  }

  &:hover > &__content {
    transform: translateX(5px);
  }
}
