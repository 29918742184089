.wallet-route {
  padding: rems(50px) 0 rems(60px) 0;
  width: 100%;

  @media screen and (max-width: $mobileMax) {
    padding: 26px 0 46px 0;
  }

  &__title {
    font-size: rems(36px);
    font-weight: 600;
    line-height: 49px;
    margin-bottom: 0;
    padding-bottom: 26px;
  }

  &__description {
    font-size: rems(14px);
    margin-bottom: 0;
    color: $color-nobel;
    max-width: 572px;

    &--dark {
      color: $color-white;
    }
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
    margin: 70px -17.5px 42px -17.5px;

    @media screen and (max-width: $mobileMax) {
      margin: 50px -17.5px 22px -17.5px;
    }
  }

  .button--link {
    @media screen and (max-width: $mobileMax) {
      margin-top: 38px;
    }
  }

  .button--primary {
    margin-right: 36px;

    @media screen and (max-width: $mobileMax) {
      margin-right: 16px;
    }
  }
}
