.react-financial-charts-candlestick {
  &-candle {
    .up,
    .down {
      stroke-width: 0;
    }
  }

  &-wick {
    .up,
    .down {
      stroke-width: 2px;
    }
  }

  .line {
    stroke-width: 2px;
  }
}

.react-financial-charts-axis {
  text {
    fill: #dcdcdc;
  }

  &--dark {
    text {
      fill: #64666d;
    }
  }
}

.react-financial-charts{
  background-color: #fff;

  &--dark {
    background-color: #2b2c31;
  }
}

.react-financial-charts-enable-interaction{
  pointer-events: none !important;
}
