.c-header {
  $root: &;
  width: 100%;
  height: 72px;
  background-color: $color-white;
  border-bottom: 1px solid $borderColor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;

  &__nav {
    height: 100%;
  }

  &__nav-item {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    position: relative;
    text-transform: uppercase;
    color: inherit;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: rems(13px);
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 24px;
      width: 40px;
      height: 5px;
      background-color: $color-safety-orange;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.2s ease;
    }

    &:link {
      text-decoration: none;
    }

    &:hover {
      color: inherit;

      &:after {
        transform: scaleX(1);
      }
    }

    &--active {
      color: $color-safety-orange;

      &:hover {
        color: $color-safety-orange;
      }

      &:after {
        transform: scaleX(1);
      }
    }

    &--dropdown {
      &:after {
        display: none;
      }
    }

    &--unlock,
    &--user {
      border-left: 1px solid $borderColor;
    }

    &:hover > .c-dropdown {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__dropdown-icon{
    width: 20px;
    margin-right: -6px;
  }

  &__logo {
    background: url("../../../assets/odee-logo.svg") no-repeat;
    width: 80px;
    height: 28px;
  }

  &--dark {
    background-color: $color-ebony;
    border-color: rgba($color-white, 0.05);

    #{$root}__nav-item {
      &--unlock,
      &--user {
        border-color: rgba($color-white, 0.05);
      }
    }

    #{$root}__logo {
      background: url("../../../assets/odee-logo-dark.svg") no-repeat;
    }
  }
}
