.ledger-instructions-modal {
  display: flex;
  flex-direction: column;

  .wallet-modal__header{
    margin-bottom: 40px;
  }

  &__list {
    margin-top: 8px;
    margin-bottom: 16px;
    list-style-position: outside;
    padding-left: 1em;
  }

  &__item{
    margin-left: 0; padding-left: 0;
  }

  &__notice{
    margin-bottom: 0;
  }

  &__btn {
    background-color: $color-royal-blue;
    margin-top: 40px;
    align-self: flex-end;
  }
}
