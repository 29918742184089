.rangeslider {
  margin: 11px 0  ;
  position: relative;
  touch-action: none;
  height: 8px;
  background: #fff;
  box-shadow: none;

  &__labels {
    position: relative;
  }

  &__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
}

.rangeslider-horizontal {
  border-radius: 10px;

  &:hover {
    opacity: 1;
  }

  .rangeslider {
    &__handle {
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      cursor: pointer;
      display: inline-block;
      position: absolute;
      box-shadow: none;
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      outline: none;

      &::after {
        height: 0;
      }
    }

    &__fill {
      display: block;
      height: 100%;
      border-radius: 10px;
      top: 0;
      box-shadow: none;
    }
  }
}
