.link {
  color: $color-royal-blue;
  cursor: pointer;
  transition: color 150ms;

  &:link {
    text-decoration: none;
  }

  &:hover {
    color: $color-royal-blue;
  }

  &--secondary {
    color: $green;

    &:hover {
      color: $green;
    }
  }

  &--underline-hover {
    &:hover {
      text-decoration: underline;
    }
  }

  &--bold {
    font-weight: 700;
  }

  &--button {
    background-color: transparent;
    border: none;
  }

}
