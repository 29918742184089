@import "../../styles/styles-old/colors.module";
@import "../../styles/styles-old/variables";
@import "../../styles/styles-old/osfont";
@import "../../styles/styles-old/abstract/functions";

.c-navigation {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  font-size: rems(13px);

  .separator {
    border-left: 1px solid #d8d8d8;
    margin-left: 16px;

    &--dark{
      border-color: rgba(255, 255, 255, 0.05);
    }
  }



  li {
    display: block;

    .c-navigation__mobile-icon {
      display: none;
    }

    > a {
      display: inline-block;
      padding: 26px 25px;
      position: relative;
      text-transform: uppercase;
      color: inherit;
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 0.8rem;
      text-decoration: none;

      &:hover {
        text-decoration: none;

        &:after {
          width: 30%;
        }
      }

      &:after {
        content: "";
        background-color: $main;
        position: absolute;
        bottom: 0;
        left: 25px;
        -webkit-transform: translateX(10%);
        transform: translateX(0%);
        width: 0;
        height: 5px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      &.active {
        color: $main;
        text-decoration: none;

        &:after {
          width: 30%;
        }
      }
    }
  }

  .c-navigation__mobile-theme {
    display: none;
  }
}

@media all and (max-width: $mobileMax) {
  .c-navigation {
    display: none;
  }
}
