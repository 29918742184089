.mobile-header {
  $root: &;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: $color-seashell;
  padding: 24px 24px 24px 54px;
  flex-direction: column;
  color: $color-manatee;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms;

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  &--dark {
    background-color: $color-midnight-express;

    #{$root}__close {
      &:after,
      &:before {
        background-color: $color-white;
      }
    }

    #{$root}__wallet {
      color: $color-white;
    }

    #{$root}__acc-icon {
      path {
        fill: $color-mischka;
      }
    }

    #{$root}__separator {
      background-color: $color-tuna-blue;
    }

    #{$root}__footer {
      color: $color-manatee;
    }
  }

  &__close {
    width: 22px;
    height: 22px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
    align-self: flex-end;
    background-color: transparent;
    border: none;

    &:after {
      content: "";
      position: absolute;
      width: 2px;
      border-radius: 1px;
      top: 0;
      bottom: 0;
      background-color: $color-black-russian;
    }

    &:before {
      content: "";
      position: absolute;
      height: 2px;
      border-radius: 1px;
      right: 0;
      left: 0;
      background-color: $color-black-russian;
    }
  }

  &__acc {
    margin-bottom: 24px;
    margin-top: 70px;
  }

  &__acc-icon {
    width: 40px;
    height: 40px;
    margin-right: 16px;

    path {
      fill: $color-shady-lady;
    }
  }

  &__wallet {
    color: $color-black-russian;
    font-size: rems(16px);
  }

  &__separator {
    width: 48px;
    height: 4px;
    background-color: #989898;
  }

  &__nav {
    margin-bottom: 24px;
    flex: 1;
  }

  &__nav-item {
    display: flex;
    align-items: center;
    margin-top: 24px;
    color: inherit;
    background-color: transparent;
    border: none;

    &:link {
      color: inherit;
      text-decoration: none;
    }

    &--active {
      color: $coral;
      &:link {
        color: $coral;
      }
    }
  }

  &__nav-icon {
    font-size: 1.25rem;
    margin-right: 24px;
    color: inherit;
  }

  &__nav-label {
    font-size: rems(18px);
    color: inherit;
    font-weight: 600;
  }

  &__footer {
    margin-bottom: 0;
    margin-top: 24px;
    max-width: 180px;
    font-size: rems(13px);
    color: $color-gray-chateau;
  }

  &__sub-check {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__sub-check:checked + &__sub {
    display: block;
  }

  &__sub {
    padding-left: 44px;
    display: none;
  }
}
