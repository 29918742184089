.table-header {
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__label {
    font-weight: 700;
  }

  &__actions {
  }
}
