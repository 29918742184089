.unlock-type {
  display: flex;
  align-items: center;
  margin-top: 38px;

  &__button {
    color: #ffffff;
    border-radius: 4px;
    border: none;
    height: 40px;
    width: 150px;
    font-weight: 700;
    font-size: rems(12px);
    text-transform: uppercase;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      width: 120px;
    }

    &--exact {
      margin-right: 15px;
      background-color: #4a90e2;
    }

    &--unlimited {
      background-color: #40c69f;
    }
  }

  &__button-sub-label {
    margin: 0;
    font-size: rems(10px);
    line-height: 1;
  }
}
