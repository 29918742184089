.orders-route {
  &__cancel {
    color: $color-royal-blue;
    background-color: transparent;
    border: none;
    font-weight: 700;
    &:hover{
      text-decoration: underline;
    }
  }
}
