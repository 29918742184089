$c-table: ".c-table";

.my-open-orders-table {
  #{$c-table}__tbody {
    #{$c-table}__tr {
      &--canceling {
        background-color: #f4f4f4;
        color: #d5d5d5;
        span {
          color: #d5d5d5;
        }
      }
    }
  }

  &--dark {
    #{$c-table}__tbody {
      #{$c-table}__tr {
        &--canceling {
          background-color: #25252a;
          color: #313237;
          span {
            color: #313237;
          }
        }
      }
    }
  }
}
