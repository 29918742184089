.token-actions-cell {
  &__button {
    $btn: &;
    border-radius: 2px;
    border: none;
    padding: 1px 8px;
    font-size: rems(10px);
    font-weight: 700;
    transition: background-color 150ms;

    &--transfer {
      background-color: #ffbb4c;
      margin-right: 24px;

      &:hover {
        background-color: rgba(#ffbb4c, 0.5);
      }
    }

    &--exchange {
      background-color: #3dbffb;

      &:hover {
        background-color: rgba(#3dbffb, 0.5);
      }
    }

    &--dark {
      color: #ffffff;
    }

    &--dark#{$btn}--transfer {
      background-color: #e38f07;
      &:hover {
        background-color: rgba(#e38f07, 0.65);
      }
    }

    &--dark#{$btn}--exchange {
      &:hover {
        background-color: rgba(#3dbffb, 0.65);
      }
    }
  }
}
