@import "abstract/functions";

.trade-orm1 {
  $root: &;
  color: $textColor;

  &__fee {
    font-size: rems(10px);
    font-weight: 800;
    color: #868788;
    padding-top: 2px;
  }

  &__amount-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -22px;
    margin-bottom: 17px;

    &--offset {
      padding-left: 50%;
    }
  }

  &__amount {
    min-width: 32px;
    background-color: #fff;
    font-size: rems(10px);
    font-weight: 800;
    border: none;
    line-height: 12px;
    border-radius: 6px;
    color: rgba(#868788, 0.5);
    outline: none !important;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &:hover {
      color: #fff;
    }
  }

  &__spread {
    display: block;
    text-align: center;
    font-size: rems(13px);
    font-weight: 700;
    color: #64666d;
    line-height: rems(18px);
  }

  &__spread-warning {
    fill: #eb6124;
    margin-right: 2px;
    height: 19px;
    pointer-events: none;
    display: block;
  }

  &__spread-tooltip {
    position: relative;
    background-color: #fff;
    font-size: rems(12px);
    font-weight: 600;
    padding: 8px;
    border-radius: 3px;
    box-shadow: 0 2px 14px rgba(#000000, 0.29);
    margin-bottom: 6px;

    &:after {
      content: "";
      position: absolute;
      width: 11px;
      height: 11px;
      transform: translateX(-50%) rotate(45deg);
      background-color: #fff;
      bottom: -4px;
      left: 50%;
    }

    &--dark {
      background-color: rgba(#131313, 0.85);
      color: #fff;

      &:after {
        background-color: rgba(#131313, 0.85);
      }
    }
  }

  &__slider-container {
    width: 100%;
    margin-bottom: 32px;
    color: #000000;
    font-weight: 700;
    font-size: rems(10px);
    border: 1px solid;
    border-radius: 5px;
    padding: 15px 20px 9px 20px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    padding: 8px 24px 8px 0;
    font-size: rems(16px);
    font-weight: 700;
  }

  &__tab {
    font-size: rems(16px);
    font-weight: 700;
    padding: 8px 24px;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    border: none;
  }

  &__form-container {
    border-top: 10px solid transparent;
    border-radius: 4px;
    padding: 30px 32px 10px 32px;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -32px;
  }

  &__form-group {
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
  }

  &__form-label {
    font-size: rems(12px);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  &__input-container {
    display: flex;
    height: 42px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #d0d2cd;
  }

  &__form-input {
    border: none;
    text-align: center;
    flex: 1;
    color: $color-black;
    font-weight: 700;
    font-size: rems(15px);
    height: 100%;
    border-left: 1px solid #d0d2cd;
    border-right: 1px solid #d0d2cd;
    width: 0;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      background-color: white;
      color: $textColor;
    }
  }

  &__input-control {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rems(27px);
    font-weight: 300;
    border: none;
    margin: 0;
    width: 42px;
    height: 100%;
    cursor: pointer;
    line-height: 1;
    background-color: transparent;
  }

  &__submit {
    padding: 0 30px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 25px;
    margin-bottom: 29px;
    font-size: rems(18px);
    font-weight: 700;
  }

  .rangeslider {
    background-color: rgba(#444752, 0.15);
  }

  &--buy {
    .rangeslider-horizontal .rangeslider__handle,
    .rangeslider-horizontal .rangeslider__fill {
      background-color: $wildWillow;
    }

    #{$root}__title {
    }

    #{$root}__tab {
      background-color: $whiteSmoke;
      color: #64666d;
      border: 1px solid #e1e6e2;
      border-bottom: none;

      &:hover {
        background-color: $wildWillow;
        color: $white;
        border-color: $wildWillow;
      }
      &--active {
        background-color: $wildWillow;
        color: $white;
        border-color: $wildWillow;
      }
    }

    #{$root}__form-container {
      border-color: $wildWillow;
      background-color: $willowBrook;
    }

    #{$root}__input-container {
      background-color: $twilightBlue;
    }

    #{$root}__form-input {
      background-color: $twilightBlue;

      &:focus {
        background-color: white;
      }
    }

    #{$root}__input-control {
      &:hover {
        background-color: $wildWillow;
        color: $white;
      }
    }

    #{$root}__submit {
      background-color: $wildWillow;
    }

    #{$root}__amount {
      &:hover {
        background-color: $wildWillow;
        color: #fff;
      }
    }

    #{$root}__slider-container {
      border-color: #d0d2cd;
      background-color: $twilightBlue;
    }
  }

  &--buy-dark {
    #{$root}__title {
      color: $color-white;
    }

    #{$root}__form-label {
      color: #64666d;
    }

    #{$root}__input-container {
      border: none;
      background-color: rgba(#000, 0.35);
    }

    #{$root}__form-input {
      border: none;
      background-color: transparent;
      color: rgba(#ffffff, 0.69);

      &:focus {
        background-color: transparent;
      }
    }

    #{$root}__submit {
      background-color: #64764a;
    }

    #{$root}__form-container {
      border-color: #64764a;
      background-color: #2d3026;
    }

    #{$root}__tab {
      border: none;
      background-color: #2d3026;
      color: #64666d;

      &:hover {
        background-color: #64764a;
        color: $white;
      }
      &--active {
        background-color: #64764a;
        color: $white;
      }
    }

    #{$root}__slider-container {
      color: $color-white;
    }

    #{$root}__input-control {
      color: #64666d;

      &:hover {
        color: $color-white;
        background-color: #64764a;
      }
    }

    .rangeslider {
      background-color: #000;
    }

    #{$root}__amount {
      background-color: rgba(#64666d, 0.5);
      color: rgba(#868788, 0.5);
    }

    .rangeslider-horizontal .rangeslider__fill,
    .rangeslider-horizontal .rangeslider__handle {
      background-color: #64764a;
    }

    #{$root}__slider-container {
      border: none;
      background-color: rgba(#000, 0.35);
    }
  }

  &--sell {
    .rangeslider-horizontal .rangeslider__handle,
    .rangeslider-horizontal .rangeslider__fill {
      background-color: $coral;
    }

    #{$root}__title {
    }

    #{$root}__tab {
      background-color: $whiteSmoke;
      color: #64666d;
      border: 1px solid #e1e6e2;
      border-bottom: none;

      &:hover {
        background-color: $coral;
        color: $white;
        border-color: $coral;
      }
      &--active {
        background-color: $coral;
        color: $white;
        border-color: $coral;
      }
    }

    #{$root}__form-container {
      border-color: $coral;
      background-color: $bridesmaid;
    }

    #{$root}__input-container {
      background-color: $fairPink;
      border-color: #e6d0c4;
    }

    #{$root}__form-input {
      background-color: $fairPink;

      &:focus {
        background-color: white;
      }
    }

    #{$root}__input-control {
      &:hover {
        background-color: $coral;
        color: $white;
      }
    }

    #{$root}__submit {
      background-color: $coral;
    }

    #{$root}__amount {
      &:hover {
        background-color: $coral;
        color: #fff;
      }
    }

    #{$root}__slider-container {
      border-color: #e6d0c4;
      background-color: $fairPink;
    }
  }

  &--sell-dark {
    #{$root}__title {
      color: $color-white;
    }

    #{$root}__form-label {
      color: #64666d;
    }

    #{$root}__input-container {
      border: none;
      background-color: rgba(#000, 0.35);
    }

    #{$root}__form-input {
      border: none;
      background-color: transparent;
      color: rgba(#ffffff, 0.69);

      &:focus {
        background-color: transparent;
      }
    }

    #{$root}__form-container {
      background-color: #342c2b;
    }

    #{$root}__tab {
      border: none;
      background-color: #342c2b;
      color: #64666d;

      &:hover {
        color: $white;
        background-color: $coral;
      }
      &--active {
        color: $white;
        background-color: $coral;
      }
    }

    #{$root}__slider-container {
      color: $color-white;
    }

    #{$root}__input-control {
      color: #64666d;

      &:hover {
        color: $color-white;
      }
    }

    .rangeslider {
      background-color: #000;
    }

    #{$root}__amount {
      background-color: rgba(#64666d, 0.5);
      color: rgba(#868788, 0.5);
    }

    #{$root}__slider-container {
      border: none;
      background-color: rgba(#000, 0.35);
    }
  }
}
