@import "../../styles/styles-old/colors.module";
@import "../../styles/styles-old/variables";
@import "../../styles/styles-old/osfont";

.header {
  &--hide {
    transform: translateY(-100%);
  }
}

.header--dark {
  background-color: #313338;
  border-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;
}

header {
  background-color: white;
  border-bottom: 1px solid $borderColor;
  transition: transform 0.25s;

  .c-header {
    height: 72px;
    padding: 0;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__mobile-menu-toggle {
      display: none;
    }

    &__logo {
      background: url("../../assets/odee-logo.svg") no-repeat;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      width: 80px;
      height: 28px;
      margin-left: 20px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      &--dark {
        background: url("../../assets/odee-logo-dark.svg") no-repeat;
      }
    }
  }
}

@media all and (max-width: $mobileMax) {
  header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;

    .c-header {
      &__container {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a {
          display: inline-block;
          padding: 0;
          margin: 0;
          height: 28px;
        }
      }

      &__mobile-menu-toggle {
        display: inline-block;

        .os-icon {
          color: $main;
          font-size: 18px;
          line-height: 18px;

          &:after {
            color: $main;
          }
        }
      }

      &__logo {
        margin: 0;
      }
    }
  }
}
