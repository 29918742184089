.open-orders-actions {
  &__label {
    margin: 0 12px;
    font-size: rems(12px);
    color: $color-shuttle-gray;

    &--dark {
      color: $color-link-water;
    }
  }
}
