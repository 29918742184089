.c-dropdown {
  $root: &;

  position: absolute;
  top: 49px;
  right: 24px;
  min-width: 200px;
  background-color: $color-safety-orange;
  border-radius: 4px;
  box-shadow: 0 10px 25px 0 rgba($color-black-pearl, 0.1);
  z-index: 1000;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.2s ease;
}
