$table: ".virtualized-table";

.sell-orders-table {
  #{$table} {
    background-color: #faeae1;
    border-color: #e1e6e2;

    &__header {
      border-color: rgba(134, 135, 136, 0.16);
    }

    &__body-cell {
      cursor: pointer;
      border-color: rgba(134, 135, 136, 0.16);

      &--selected {
        background-color: hsla(0, 0%, 100%, 0.75);
      }
    }
  }

  .full {
    font-weight: 700;
    color: #000;
  }

  .partial {
    color: #ff3436;
  }

  &--dark {
    #{$table} {
      background-color: #342c2b;
      border-color: transparent;

      &__header {
        border-color: #3a3b3f;
      }

      &__body-cell {
        border-color: #3a3b3f;
        color: #bec4d2;

        &--selected {
          background-color: rgba(35, 38, 37, 0.75);
        }
      }
    }

    .full {
      font-weight: 700;
      color: #fff;
    }

    .partial {
      color: #ff760f;
    }
  }
}
