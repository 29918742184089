@import "../../styles/styles-old/abstract/functions";

.button {
  font-size: rems(12px);
  font-weight: 700;
  color: #fff;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  padding: 12px;
  text-transform: uppercase;
  margin-top: 45px;
}
