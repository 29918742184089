.order-type{
  font-size: rems(12px);

  &--buy{
    color: $green;
  }

  &--sell{
    color: $red;
  }
}
