@import "colors.module";
@import "abstract/functions";

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

#root {

}

h5,
.h5 {
  font-size: 1.25rem;
}

svg {
  text {
    font-weight: 700;
    font-size: rems(10px);
    fill: #abacac;
  }
}
