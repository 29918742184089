.header-sort-cell {
  position: relative;
  padding-right: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    top: 7px;
    border-style: solid;
    display: none;
  }

  &--asc:after {
    display: block;
    border-color: transparent transparent #528cf7 transparent;
    border-width: 0 3.43px 3.66px 3.43px;
  }

  &--desc:after {
    display: block;
    border-color: #528cf7 transparent transparent transparent;
    border-width: 3.66px 3.43px 0 3.43px;
  }

  &__description {
    color: #528cf7;
    font-size: rems(10px);
    margin-left: 5px;
  }
}
