.token-info {
  $root: &;
  margin-top: 4px;

  &--dark {
    #{$root}__value,
    #{$root}__value-currency {
      color: $color-gray-chateau;
    }

    #{$root}__label {
      color: $color-shuttle-gray;
    }

    #{$root}__row {
      border-bottom-color: $color-steel-gray;
    }
  }

  &__row {
    border-bottom: 1px solid $color-zircon;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__column {
    padding: 16px 0 8px 0;

    &:not(:last-child) {
      padding-right: 24px;
    }
  }

  &__label {
    font-size: rems(10px);
    font-weight: 800;
    line-height: rems(14px);
    white-space: nowrap;
    color: $color-periglacial-blue;
  }

  &__value {
    font-size: rems(20px);
    line-height: rems(27px);
    white-space: nowrap;
    color: $color-black;
    margin-top: -3px;
  }

  &__value-currency {
    font-size: rems(14px);
    font-weight: 700;
    color: $color-periglacial-blue;
  }

  &__icon {
    height: 32px;
    width: auto;
    margin-right: 14px;
  }
}
