.compare-slider {
  $root: &;
  background-color: #fff;
  border-radius: 6px;
  height: 274px;
  position: relative;

  &--dark {
    background-color: #2b2c31;

    #{$root}__side{
      background-color: #2b2c31;
    }
  }

  &__side {
    min-width: 50%;
    width: 50%;
    height: 100%;
    background-color: #fff;

    &--left {
      position: relative;
    }

    &--right {
      position: absolute;
      right: 0;
      top: 0;
    }

    &--disabled {
      pointer-events: none;
    }
  }



  &__separator {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    cursor: ew-resize;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 34px;
    z-index: 100;
  }

  &__separator-line {
    width: 2px;
    height: 100%;
    background-color: #ffd012;
    pointer-events: none;
  }


  &__separator-arrow{
    pointer-events: none;
    &--right{
      margin-left: 4px;
    }

    &--left{
      transform: rotate(180deg);
      margin-right: 4px;
    }
  }
}
