.c-table {
  $root: &;

  &__sort {
    position: relative;
    display: inline-flex;
    padding-right: 12px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      top: 7px;
      border-style: solid;
      border-width: 3.66px 3.43px 0 3.43px;
      transform: translateY(3px);
      border-color: $color-echo-blue transparent transparent transparent;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      bottom: 7px;
      border-style: solid;
      border-width: 0 3.43px 3.66px 3.43px;
      transform: translateY(-3px);
      border-color: transparent transparent $color-echo-blue transparent;
    }

    &--asc {
      &:after {
        border-color: transparent transparent $green transparent;
      }
    }

    &--desc {
      &:before {
        border-color: $red transparent transparent transparent;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 6px;
  }

  &__label {
    font-size: rems(16px);
    font-weight: 700;
    color: $color-merlin;
  }

  &__body {
    background-color: $color-white;
    border-radius: 4px;
    border: 1px solid $color-aqua-squeeze;
    max-height: 314px;
    overflow: auto;
  }

  &__table {
    width: 100%;
  }

  &__tr {
    height: 39px;
    border-color: rgba($color-aluminium, 0.16);
    color: $color-tuna-blue;

    &--body {
      border-top: 1px solid rgba($color-aluminium, 0.16);

      &:hover {
        background-color: $color-white-lilac;
      }
    }

    &--selected {
      background-color: $color-white-lilac;
    }

    &--head {
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__td {
    padding: 0 24px;
    font-size: rems(12px);
    white-space: nowrap;

    &--bold {
      font-weight: 700;
    }
  }

  &__th {
    font-size: rems(12px);
    font-weight: 700;
    color: $color-merlin;
    padding: 0 24px;
    white-space: nowrap;
  }

  &__canceling-button{
    margin-left: 7px;
  }

  .sk-fading-circle .sk-circle:before {
    background-color: #ff3436;
  }

  &--dark {
    #{$root}__label {
      color: $color-white;
    }

    #{$root}__body {
      background-color: $color-bastille;
      border: none;
    }

    #{$root}__tr {
      border-color: $color-vulcan-light;
      color: $color-shuttle-gray;

      &--body {
        border-color: $color-vulcan-light;
        &:hover {
          background-color: $color-vulcan-light;
        }
      }

      &--selected {
        background-color: $color-vulcan-light;
      }
    }

    #{$root}__th {
      color: $color-link-water;
    }

    #{$root}__td {
      &--bold {
        color: $color-white;
      }
    }

    .sk-fading-circle .sk-circle:before {
      background-color: #ff7c56;
    }
  }
}

.c-table-animated {
  .item-exit {
    opacity: 1;
  }

  .item-exit-active {
    opacity: 0;
    transition: opacity 1000ms ease-in;
  }

  .empty-item-exit,
  .empty-item-enter {
    display: none;
  }

  .empty-item-enter-done {
    display: table-row;
  }
}

