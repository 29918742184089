@import "../../styles/styles-old/colors.module";
@import "../../styles/styles-old/variables";
@import "../../styles/styles-old/osfont";

.c-navigation-dropdown {
  position: relative;
  cursor: pointer;

  .c-navigation-dropdown__toggle-button,
  .c-navigation-dropdown__mobile-icon {
    display: none;
  }

  &__text {
    position: relative;

    padding: 26px 25px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    text-transform: uppercase;
    color: $textColor;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.8rem;
    display: flex;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 33px;
      right: 10px;
      border-style: solid;
      border-width: 4.91px 4.245px 0 4.245px;
      border-color: $textColor transparent transparent transparent;
    }

    &--dark {
      color: $white;

      &:after {
        border-color: $white transparent transparent transparent;
      }
    }
  }

  &__list {
    width: 200px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 110px;
    right: 5px;
    background: $main;
    color: white;
    padding: 0;
    z-index: 999;
    border-radius: 4px;
    -webkit-box-shadow: 0 10px 25px 0 rgba(26, 32, 39, 0.1);
    box-shadow: 0 10px 25px 0 rgba(26, 32, 39, 0.1);
    overflow: hidden;
    font-size: 0.9rem;
    text-align: left;
    transition: all 0.2s ease;

    > ul {
      margin: 0;
      padding: 0;

      li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        padding: 0 25px;
        height: 63px;
        align-items: center;
        letter-spacing: 1px;
        font-weight: bold;
        white-space: nowrap;

        a {
          padding: 0;
          color: inherit;
          text-decoration: none;
        }

        .c-navigation-dropdown__list-item-text {
          display: inline-flex;
          align-items: center;
          transition: all 0.2s ease;
          vertical-align: middle;
          padding-right: 5px;
          font-size: 0.8rem;
          width: 100%;
          height: 100%;
        }

        .c-navigation-dropdown__list-item-text--none {
          display: inline-flex;
          align-items: center;
          transition: all 0.2s ease;
          vertical-align: middle;
          padding-right: 5px;
          font-size: 0.8rem;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        .os-icon {
          display: inline-block;
          font-size: 22px;
          vertical-align: middle;
          margin-left: 15px;
          pointer-events: none;
        }

        &:hover {
          a,
          div {
            padding-right: 0;
            padding-left: 5px;
          }

          .os-icon {
            transform: scale(1.2);
          }
        }
      }
    }

    &--visible {
      top: 50px;
      visibility: visible;
      opacity: 1;
    }
  }
}

@media all and (max-width: $mobileMax) {
  .c-navigation-dropdown {
    display: none;

    &__menu-container {
      flex-basis: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .c-navigation-dropdown__toggle-button {
      display: inline-block;
      float: right;
      vertical-align: middle;
      padding: 7px;
    }

    .c-navigation-dropdown__mobile-icon {
      display: inline-block;
      font-size: 27px;
      color: $main;
      width: 80px;
      text-align: center;
    }

    .c-navigation-dropdown__text {
      padding: 0;
      display: inline-block;
      position: relative;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      text-transform: uppercase;
      color: $textColor;
      font-weight: 700;
      letter-spacing: 1px;
      vertical-align: super;
    }

    &__list {
      position: static;
      visibility: visible;
      opacity: 1;
      display: none;
      flex-basis: 100%;
      width: 100%;
      background: white;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      color: $textColor;

      &--visible {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        display: block;
      }

      ul {
        li {
          padding-left: 80px;
          display: block;
          width: 100%;

          a {
            color: $textColor;
          }

          &:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          }
        }
      }
    }

    &__list-item-text {
      display: inline-block;
    }
  }
}
