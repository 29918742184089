.wallet-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 260px;
  min-width: 210px;
  border-radius: 12px;
  background-color: $color-white;
  box-shadow: 0 1px 2px rgba($color-black, 0.2);
  padding: 25px 20px;
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;
  outline: none;
  border: none;
  color: inherit;
  margin: 0 17.5px 36px 17.5px;

  &__label {
    font-size: rems(26px);
    font-weight: 600;
    padding-top: 10px;
    line-height: 1.2;
    text-align: left;
    max-width: 170px;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      letter-spacing: -1px;
    }
  }

  &__icon{
    width: 24px;
    height: auto;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      width: 18px;
    }
  }

  &__bg {
    transform: scaleX(1.15);
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;

    @media screen and (max-width: $mobileMax) {
      transform: scaleX(1.15) translateY(12px);
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  &--dark {
    background: $color-bastille;
    box-shadow: 0 0 18px rgba($color-black, 0.41);
  }

  &--disabled {
    filter: grayscale(100%);
  }

  @media (max-width: map_get($grid-breakpoints, "sm")) {
    width: calc(50% - 37px);
    min-width: auto;
    min-height: 200px;

    &:hover {
      transform: scale(1);
    }
  }
}
