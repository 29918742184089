@import "abstract/functions";
@import "abstract/variables";

.custom-modal {
  $root: &;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(#000000, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9b9b9b;

  &--dark {
    background-color: rgba(#000000, 0.45);
    color: #fff;

    #{$root}__content-container {
      background-color: #2b2c31;
      box-shadow: 0 2px 48px rgba(#0c0d0e, 0.42);
    }

    #{$root}__title,
    #{$root}__subtitle {
      color: #fff;
    }

    #{$root}__text{
      color: #BEC4D2;
    }
  }

  &__position {
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: $mobileMax) {
      height: 100%;
    }
  }

  &__content-container {
    overflow-y: auto;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 48px rgba(#3e4350, 0.42);
    max-height: 690px;
    max-width: 840px;
    padding: 44px 86px 93px 86px;

    @media (max-width: map_get($grid-breakpoints, "md")) {
      padding: 44px 50px 93px 50px;
    }

    @media (max-width: $mobileMax) {
      padding: 26px 20px 70px 20px !important;
      margin: 0;
      max-height: 100%;
      height: 100%;
      border-radius: 0;
    }
  }

  &__close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;

    @media (max-width: $mobileMax) {
      display: none;
    }
  }

  &__close-btn {
    align-self: flex-end;
    @media (max-width: map_get($grid-breakpoints, "md")) {
      align-self: center;
      width: 234px;
    }
  }

  &__to-top {
    position: absolute;
    right: 30px;
    bottom: 20px;
    cursor: pointer;
    fill: #9b9b9b;
    width: 36px;
    height: 36px;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__text {
    font-size: rems(14px);
    margin: 0;
    padding-bottom: 1rem;
  }

  &__title {
    color: #4e4d49;
    font-size: rems(36px);
    font-weight: 600;
    line-height: rems(49px);
    letter-spacing: rems(-0.3px);
    text-align: center;
    margin: 0;
    padding-bottom: rems(53px);

    @media (max-width: map_get($grid-breakpoints, "md")) {
      padding-bottom: rems(41px);
    }
  }

  &__subtitle {
    color: #4e4d49;
    font-size: rems(16px);
    font-weight: 600;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: rems(14px);
  }

  &__link {
    cursor: pointer;
    color: #4a90e2;
    &:hover {
      color: #4a90e2;
    }
  }
}
