.overtime-indicator {
  $root: &;
  top: 198px;
  position: absolute;
  display: flex;

  &--dark {
    #{$root}__dot {
      background-color: #424347;
      &--selected {
        background-color: #4a90e2;
      }
    }
  }

  &__dot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #d0d2cd;
    margin-right: 7px;
    transition: background-color 0.4s;

    &--selected {
      background-color: #4a90e2;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: map_get($grid-breakpoints, "sm")) {
    top: 146px;
  }
}
