.block-chain-processing {
  $root: &;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 170px;
  position: absolute;
  top: 192px;

  &--dark {
    #{$root}__current,
    #{$root}__start {
      color: #ffffff;
    }

    #{$root}__label {
      color: #7d7c7c;
    }

    #{$root}__indicator-container {
      background-color: #191a1c;
    }
  }

  &__label {
    font-size: rems(9px);
    font-weight: 700;
    letter-spacing: 2px;
    color: #7d7c7c;
    width: 100%;
    text-align: center;
    margin-bottom: 3px;
  }

  &__indicator-container {
    width: 100%;
    height: 4px;
    background-color: #dde2ee;
    border-radius: 2px;
    overflow: hidden;
  }

  &__indicator {
    background-color: #2a9ff8;
    width: 100%;
    height: 100%;
    transform-origin: left;
    transform: scaleX(0);
  }

  &__current,
  &__start {
    position: absolute;
    bottom: 2px;
    transform: translateY(50%);
    font-size: rems(13px);
    font-weight: 600;
    color: #23262f;
  }

  &__start {
    left: -8px;
    transform: translateY(50%) translateX(-100%);
  }

  &__current {
    right: -8px;
    transform: translateY(50%) translateX(100%);
  }

  @media (max-width: map_get($grid-breakpoints, "sm")) {
    top: 140px;
  }
}
