$table: ".virtualized-table";

.buy-orders-table {
  #{$table} {
    background-color: #e7edda;
    border-color: #e1e6e2;

    &__header {
      border-color: rgba(134, 135, 136, 0.16);
    }

    &__body-cell {
      cursor: pointer;
      border-color: rgba(134, 135, 136, 0.16);

      &--selected {
        background-color: #f8f8f9;
      }
    }
  }

  .full {
    font-weight: 700;
    color: #000;
  }

  .partial {
    color: #27b77f;
  }

  &--dark {
    #{$table} {
      background-color: #2d3026;
      border-color: transparent;

      &__header {
        border-color: #3a3b3f;
      }

      &__body-cell {
        border-color: #3a3b3f;
        color: #bec4d2;

        &--selected {
          background-color: rgba(35, 38, 37, 0.75);
        }
      }
    }

    .full {
      font-weight: 700;
      color: #fff;
    }

    .partial {
      color: #d8ff7b;
    }
  }
}
