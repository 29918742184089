.custom-tooltip {
  &__container {
    z-index: 1000;
    position: absolute;
    transform: translateX(-50%) translateY(-100%);
    &--hidden {
      &:not(:hover){
        display: none;
      }
    }
  }
}
