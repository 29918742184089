.error-icon-with-tooltip {
  &__icon {
    fill: #eb6124;
    margin-right: 2px;
    pointer-events: none;
    display: block;
    margin-left: 4px;
  }

  &__tooltip {
    position: relative;
    background-color: #fff;
    font-size: rems(12px);
    font-weight: 600;
    padding: 8px;
    border-radius: 3px;
    box-shadow: 0 2px 14px rgba(#000000, 0.29);
    margin-bottom: 6px;

    &:after {
      content: "";
      position: absolute;
      width: 11px;
      height: 11px;
      transform: translateX(-50%) rotate(45deg);
      background-color: #fff;
      bottom: -4px;
      left: 50%;
    }

    &--dark {
      background-color: rgba(#131313, 0.85);
      color: #fff;

      &:after {
        background-color: rgba(#131313, 0.85);
      }
    }
  }
}
