$c-table: ".c-table";

.trade-history-table {
  #{$c-table}__tbody {
    #{$c-table}__tr {
      &--my {
        color: #FF7C56;
      }
    }
  }
}
