$trade-form-input: ".trade-form-input";

#{$trade-form-input} {
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;

  &__label {
    width: 100%;
    font-size: rems(12px);
    font-weight: 700;
    color: $color-shuttle-gray;
    margin-bottom: 3px;
    display: block;
  }

  &__button {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    border: 1px solid $color-gray-nurse;
    touch-action: manipulation;

    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $color-merlin;
    }

    &--minus {
      border-right: none;
      border-radius: 4px 0 0 4px;
    }

    &--plus {
      border-left: none;
      border-radius: 0 4px 4px 0;

      &:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 16px;
        background-color: $color-merlin;
      }
    }
  }

  &__input {
    appearance: none;
    font-size: rems(15px);
    font-weight: 700;
    color: $color-vulcan;
    min-width: 0;
    height: 42px;
    flex: 1;
    background-color: transparent;
    text-align: center;
    border: 1px solid $color-gray-nurse;
    border-radius: 0;

    &--rounded {
      border-radius: 4px;
    }
  }
}

.buy-trade-form-input {
  #{$trade-form-input}__input {
    background-color: $twilightBlue;
  }

  #{$trade-form-input}__button {
    background-color: $twilightBlue;

    &:hover {
      background-color: $color-olivine;

      &:after,
      &:before {
        background-color: $color-white;
      }
    }
  }

  &--dark {
    #{$trade-form-input}__input {
      color: $color-silver;
      background-color: rgba($color-black, 0.35);
      border: none;
    }

    #{$trade-form-input}__button {
      background-color: rgba($color-black, 0.35);
      border: none;

      &:hover {
        background-color: $color-dingley;
      }

      &:after,
      &:before {
        background-color: $color-shuttle-gray;
      }
    }
  }
}

.sell-trade-form-input {
  #{$trade-form-input}__input {
    background-color: $color-seashell;
    border-color: $color-bizarre;
  }

  #{$trade-form-input}__button {
    background-color: $color-seashell;
    border-color: $color-bizarre;

    &:hover {
      background-color: $color-coral;

      &:after,
      &:before {
        background-color: $color-white;
      }
    }
  }

  &--dark {
    #{$trade-form-input}__input {
      color: $color-silver;
      background-color: rgba($color-black, 0.35);
      border: none;
    }

    #{$trade-form-input}__button {
      background-color: rgba($color-black, 0.35);
      border: none;

      &:after,
      &:before {
        background-color: $color-shuttle-gray;
      }
    }
  }
}
