.bc-modal {
  $root: &;
  width: 590px;
  min-height: 326px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 138px 0 0 0;
  position: relative;

  &:after,
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 110px;
    height: 4px;
  }

  &:after {
    background-color: #40c69f;
    transform: translateX(50%);
  }

  &:before {
    background-color: #4a90e2;
    transform: translateX(-50%);
  }

  &__type-icon {
    height: 48px;
    width: auto;
    position: absolute;
    top: 65px;
  }

  &__label {
    font-size: rems(36px);
    color: #4e4d49;
    font-weight: 600;
    margin-bottom: 32px;
  }

  &__description {
    font-size: rems(14px);
    line-height: rems(22px);
    color: #7d7c7c;
    max-width: 470px;
    width: 100%;
    text-align: center;
    margin: 0;
  }

  @media (max-width: map_get($grid-breakpoints, "sm")) {
    width: 335px;
    min-height: 363px;
    padding: 170px 0 0 0;

    &__label {
      margin-bottom: 22px;
    }

    &__description {
      padding: 0 14px;
    }

    &__type-icon {
      top: 78px;
    }
  }

  &--dark {
    #{$root}__label {
      color: #ffffff;
    }
    #{$root}__description {
      color: #ecf1ff;
    }
  }

  &--unlock-type {
    #{$root}__label {
      margin: 0;
    }

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      padding: 150px 0 0 0;
    }
  }

  @media (max-width: $mobileMax) {
    height: 100%;
    width: 100%;
    padding-left:  6px;
    padding-right: 6px;
  }
}
