.wallet-modal {
  $root: &;
  position: relative;
  background-color: $color-white;
  padding: 70px 60px;
  border-radius: 8px;
  box-shadow: 0 2px 48px rgba(#3e4350, 0.42);
  margin: 0 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $mobileMax){
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  &--dark {
    background-color: $color-bastille;
    box-shadow: 0 2px 48px rgba(#0c0d0e, 0.42);

    #{$root}__close {
      &:after,
      &:before {
        background-color: $color-shuttle-gray;
      }
    }

    #{$root}__header {
      color: $color-white;
    }

    #{$root}__input {
      background-color: $color-steel-gray;
      border: none;
      color: $color-white;

      &::placeholder {
        color: $color-storm-gray;
      }
    }

    #{$root}__input-label {
      color: $color-shuttle-gray;
    }

    #{$root}__browse-btn {
      background-color: $color-black-russian;
      color: $color-french-gray;
    }

    #{$root}__message {
      color: $color-white;
    }
  }

  &--mw590 {
    max-width: 590px;
    width: 100%;
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
    align-self: flex-end;
    background-color: transparent;
    border: none;

    &:after {
      content: "";
      position: absolute;
      width: 2px;
      border-radius: 1px;
      top: 0;
      bottom: 0;
      background-color: $color-shady-lady;
    }

    &:before {
      content: "";
      position: absolute;
      height: 2px;
      border-radius: 1px;
      right: 0;
      left: 0;
      background-color: $color-shady-lady;
    }

    @media screen and (max-width: $mobileMax){
      display: none;
    }
  }

  &__header {
    font-size: rems(30px);
    letter-spacing: -1px;
    color: $color-merlin;
    font-weight: 600;
    margin-bottom: 0;

    &--center {
      text-align: center;
    }
  }

  &__input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 40px;
    width: 100%;
  }

  &__input-label {
    color: $color-gray;
    font-size: rems(12px);
    font-weight: 700;
    margin-bottom: 7px;
  }

  &__input {
    height: 38px;
    border-radius: 4px;
    border: 1px solid #e1e6e2;
    padding: 0 18px;
    font-weight: 600;
    width: 100%;
    color: $color-merlin;

    &--textarea {
      height: 150px;
      padding: 18px;
    }

    &::placeholder {
      color: $color-mischka-light;
    }
  }

  &__input-browse {
    pointer-events: none;
    border-radius: 4px 0 0 4px;
  }

  &__browse-btn {
    font-size: rems(12px);
    font-weight: 700;
    color: $color-black-russian;
    padding: 0 20px;
    border: none;
    background-color: $color-zumthor;
    border-radius: 0 4px 4px 0;
  }

  &__input-file {
    display: none;
  }

  &__actions {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    background-color: rebeccapurple;
    color: $color-white;
    border: none;
    padding: 12px 22px;
    border-radius: 4px;
    font-size: rems(12px);
    font-weight: 700;

    &--unlock {
      background-color: $color-royal-blue;
      margin-left: 34px;
    }

    &--cancel {
      background-color: $color-shamrock;
    }
  }

  &__message {
    display: block;
    margin-top: 30px;
    font-size: rems(14px);
    line-height: rems(22px);
    color: $color-gray;
  }

  &__err-msg {
    position: absolute;
    bottom: -2px;
    transform: translateY(100%);
    color: $color-red-orange;
    font-size: rems(12px);
    font-weight: 700;
  }
}

@media screen and (max-width: $mobileMax) {
  .wallet-modal {
    padding-left: 20px;
    padding-right: 20px;

    &__btn {
      flex: 1;

      &--unlock {
        margin-left: 15px;
      }
    }
  }
}
