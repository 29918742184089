.virtualized-table {
  $root: &;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e6e2;
  overflow: hidden;
  font-size: rems(12px);

  &__header,
  &__body {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  &__header {
    border-bottom: 1px solid #ececed;
  }

  &__body {
    &--pagination {
      overflow-y: hidden !important;
    }
  }

  &__body-cell,
  &__header-cell {
    display: flex;
    align-items: center;
    padding-left: 24px;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      padding-left: 12px;
    }
  }

  &__header-cell {
    color: #4e4d49;
    font-weight: 700;
  }

  &__body-cell {
    color: #444752;
    border-bottom: 1px solid #ececed;

    &:last-child {
      //border-bottom-color: transparent;
    }

    &--selected {
      @media (hover: hover) and (pointer: fine) {
        background-color: #f8f8f9;
      }
    }

    &--bold {
      font-weight: 700;
      color: #000000;
    }

    &--with-content-wrap {
      #{$root}__cell-content {
        white-space: unset;
        text-overflow: unset;
        overflow: unset;
      }
    }
  }

  &__cell-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &--dark {
    background-color: #2b2c31;
    border-color: #3c3f48;

    #{$root}__header {
      border-color: #34353c;
    }

    #{$root}__header-cell {
      color: #ffffff;
    }

    #{$root}__body-cell {
      border-color: #34353c;
      color: #b2b4b7;

      &--bold {
        color: #ffffff;
      }

      &--selected {
        @media (hover: hover) and (pointer: fine) {
          background-color: #3a3b3f;
        }
      }
    }
  }
}
