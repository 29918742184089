.dropped-orders-modal {
  .custom-modal {
    &__title {
      width: 100%;
      text-align: center;
    }

    &__content-container {
      max-width: 700px;
      padding: 44px 86px 44px 86px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
