@import "abstract/variables";

.search-input {
  $root: &;
  position: relative;
  display: flex;
  height: 28px;
  align-items: center;

  &--dark {
    #{$root}__input {
      background-color: #2b2c31;
      border-color: transparent;
    }

    #{$root}__icon-container {
      background-color: transparent;
    }

    #{$root}__icon {
      fill: #f4f7f9;
    }
  }

  &__input {
    width: 215px;
    height: 100%;
    box-shadow: none;
    -webkit-appearance: none;
    border-radius: 14px;
    background-color: #ffffff;
    border: 1px solid #e1e6e2;
    padding: 0 34px 0 18px;
    font-size: rems(14px);
    font-weight: 600;
    box-sizing: border-box;
    color: #69727c;

    &::placeholder {
      color: #69727c;
    }

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      font-size: rems(16px);
      &:not(:focus) {
        width: 28px;
        padding: 0;
        color: transparent;
        &::placeholder {
          color: transparent;
        }
      }

      &:focus {
        position: absolute;
        right: 0;
        width: 215px;
      }
    }
  }

  &__icon-container {
    pointer-events: none;
    position: absolute;
    right: 2px;
    width: 24px;
    height: 24px;
    background-color: #e0e2e5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 12px;
    height: 12px;
    fill: #989898;
  }
}
