@import "../../styles/styles-old/colors.module";
@import "DropdownView.module";

.c-user-info {

    &__icon {
        border-left: 1px solid $borderColor;
        display: inline-block;
        vertical-align: middle;
        padding: 26px 25px;
        margin-left: 16px;

        &--dark{
            border-color: rgba(255, 255, 255, 0.05);
        }


        @media all and (max-width: $mobileMax) {
            display: none;
            border-left: none;
            padding: 0;
        }
    }

    &__icon-image {
        width: 18px;
        height: 18px;
    }
}

.c-navigation-dropdown__text {
    display: none;
}

