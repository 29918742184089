.all-trade-history-table {
  $root: &;
  &__cancel {
    color: $color-royal-blue;
    font-weight: 700;
    background-color: transparent;
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &--dark {
    #{$root}__cancel-cell.virtualized-table__body-cell {
      background-color: #25252a;

      span,
      span > span {
        color: #313237;
      }
    }
  }

  &__cancel-cell.virtualized-table__body-cell {
    background-color: #f4f4f4;

    span,
    span > span {
      color: #d5d5d5;
    }
  }
}
