$trade-form: ".trade-form";

#{$trade-form} {
  &__header {
    position: relative;
    padding-bottom: 10px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 10px;
      border-radius: 4px 4px 0 0;
    }
  }

  &__header-label {
    font-weight: 700;
    color: $color-merlin;
    margin-right: 26px;
  }

  &__header-tab {
    padding: 4px 26px;
    background-color: $color-solitude-dark;
    border: 1px solid $color-aqua-squeeze;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    font-size: rems(16px);
    font-weight: 700;
    color: $color-shuttle-gray;

    &:focus {
      outline: none;
    }

    &--selected {
      color: $color-white;
    }
  }

  &__body {
    padding: 20px;
    border: 1px solid $color-aqua-squeeze;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    color: $color-white;
    border: none;
    border-radius: 4px;
    font-size: rems(18px);
    font-weight: 700;
    line-height: 24px;
  }

  &__group {
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @media (max-width: map_get($grid-breakpoints, "md")) {
      &:not(:last-child) {
        margin-bottom: 0;

        & > * {
          margin-bottom: 24px;
        }
      }
    }
  }

  &__fee {
    font-size: rems(10px);
    font-weight: 800;
    color: $color-aluminium;
    margin-top: 3px;
    display: block;

    @media (max-width: map_get($grid-breakpoints, "md")) {
      text-align: center;
    }
  }

  &--dark {
    #{$trade-form}__header-label {
      color: $color-white;
    }
  }
}

.buy-trade-form {
  #{$trade-form}__header {
    &:after {
      background-color: $color-olivine;
    }
  }

  #{$trade-form}__body {
    background-color: $color-sugar-cane;
  }

  #{$trade-form}__header-tab {
    &--selected {
      background-color: $color-olivine;
      border-color: $color-olivine;
    }
  }

  #{$trade-form}__button {
    background-color: $color-olivine;
  }

  &--dark {
    #{$trade-form}__header {
      &:after {
        background-color: $color-dingley;
      }
    }

    #{$trade-form}__body {
      background-color: $color-rangoon-green;
      border-color: $color-rangoon-green;
    }

    #{$trade-form}__header-tab {
      background-color: $color-rangoon-green;
      border-color: $color-rangoon-green;

      &--selected {
        background-color: $color-dingley;
        border-color: $color-dingley;
      }
    }

    #{$trade-form}__button {
      background-color: $color-dingley;
    }
  }
}

.sell-trade-form {
  #{$trade-form}__header {
    &:after {
      background-color: $color-coral;
    }
  }

  #{$trade-form}__body {
    background-color: $color-bridesmaid;
  }

  #{$trade-form}__header-tab {
    &--selected {
      background-color: $color-coral;
      border-color: $color-coral;
    }
  }

  #{$trade-form}__button {
    background-color: $color-coral;
  }

  &--dark {
    #{$trade-form}__header {
      &:after {
        background-color: $color-coral;
      }
    }

    #{$trade-form}__body {
      background-color: $color-livid-brown;
      border-color: $color-livid-brown;
    }

    #{$trade-form}__header-tab {
      background-color: $color-livid-brown;
      border-color: $color-livid-brown;

      &--selected {
        background-color: $color-coral;
        border-color: $color-coral;
      }
    }

    #{$trade-form}__button {
      background-color: $color-coral;
    }
  }
}
