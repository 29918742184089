// Colors
$blue: #047bf8;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ff4777;
$orange: #fd7e14;
$yellow: #fbe4a0;
$green: #27b77f;
$teal: #20c997;
$cyan: #5bc0de;
$white: #fff;
$gray: #636c72;
$gray-dark: #343a40;
$primary: #047bf8;
$secondary: #292b2c;
$bunting: #293145;
$hawkesBlue: #ccd9e8;
$gulfBlue: #323c58;
$midnightExpress: #252c3c;

$success: #24b314;
$info: #5bc0de;
$warning: #fbe4a0;
$danger: #e65252;
$light: #f8f9fa;
$dark: #343a40;

$main: #f46109;
$backgroundColor: #fafafa;
$borderColor: #d8d8d8;
$textColor: #464b50;
$tableBorderColor: rgba(83, 101, 140, 0.05);
$tableBoxShadow: 0 2px 4px rgba(#000, 0.16);
$depositGreen: #24b314;

$whiteSmoke: #f2f2f2;
$osloGray: #868787;
$twilightBlue: #f6f9ef;
$willowBrook: #dfe9d1;
$wildWillow: #aac56b;
$fairPink: #fbf5f3;
$bridesmaid: #faeae1;
$coral: #ff7c56;

$color-white: #ffffff;
$color-white-dark: #fafafa;
$color-white-smoke: #f2f2f2;
$color-white-smoke-dark: #ececec;
$color-aqua-squeeze: #e1e6e2;
$color-zircon: #e4e5e6;
$color-solitude-light: #f7f9fa;
$color-solitude: #edeff3;
$color-solitude-dark: #EFF1F5;
$color-white-lilac: #f8f8f9;
$color-gainsboro: #d8d8d8;
$color-ghost: #c8c8c9;
$color-periglacial-blue: #b2b4b4;
$color-nobel: #9b9b9b;
$color-gray-nurse: #D0D2CD;
$color-oslo-gray: #868787;
$color-dim-gray: #707070;
$color-gray-chateau: #969aa2;
$color-silver: #BBB9B9;
$color-shuttle-gray: #64666d;
$color-bright-gray: #575b67;
$color-tuna: #464b50;
$color-comet: #65656F;
$color-tuna-blue: #444752;
$color-steel-gray: #424448;
$color-aluminium: #868788;
$color-paynes-grey: #414248;
$color-vulcan: #35383d;
$color-vulcan-light: #3a3b3f;
$color-ebony: #313338;
$color-bastille: #2b2c31;
$color-black-pearl: #1a2027;
$color-maire: #1e1e19;
$color-black: #000000;

$color-twilight-blue: #f6f9ef;
$color-cosmic-latte: #e3f7f1;
$color-willow-brook: #dfe9d1;
$color-celery: #99c046;
$color-olivine: #a0b966;
$color-dingley: #64764a;
$color-wild-willow: #aac56b;
$color-kelly-green: #24b314;
$color-shamrock: #40c69f;
$color-sugar-cane: #e7edda;
$color-feta: #d7dccc;
$color-rangoon-green: #2d3026;

$color-link-water: #bec4d2;
$color-echo-blue: #adb7d0;
$color-ship-cove: #7e8eb1;
$color-dodger-blue: #3dbffb;
$color-dodger-blue-dark: #2A9FF8;
$color-viking: #3e9fce;
$color-royal-blue: #4a90e2;
$color-columbia-blue: #9edffd;
$color-boston-blue: #3e7f9f;

$color-fair-pink: #fbf5f3;
$color-bridesmaid: #faeae1;
$color-rose-bud: #ffa2a4;
$color-golden-tainoi: #ffbb4c;
$color-gamboge: #e38f07;
$color-coral: #ff7c56;
$color-safety-orange: #f46109;
$color-flamingo: #e65252;
$color-medium-carmine: #b9393c;
$color-red-orange: #ff3436;
$color-bizarre: #E6D0C4;
$color-hacienda: #aa741e;
$color-livid-brown: #342c2b;
$colo-cape-honey: #ffdda5;

$color-seashell: #fff8f4;
$color-mischka: #98a2ae;
$color-mischka-light: #b2b4b7;
$color-shady-lady: #989898;
$color-gray-chateau: #979899;
$color-gray: #7d7c7c;
$color-merlin: #4e4d49;
$color-zumthor: #ced2d4;
$color-storm-gray: #76787d;
$color-manatee: #838a9b;
$color-french-gray: #bab9bb;
$color-woodsmoke: #232625;
$color-black-russian: #23262f;
$color-black-russian-light: #242529;
$color-midnight-express: #010102;
