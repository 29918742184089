$trade-form-balance-picker: ".trade-form-balance-picker";

#{$trade-form-balance-picker} {
  margin-top: 3px;
  display: flex;

  &__btn {
    font-size: rems(10px);
    font-weight: 800;
    line-height: rems(14px);
    border: none;
    background-color: rgba($color-white, 0.7);
    padding: 1px 4px;
    border-radius: 8px;
    color: $color-aluminium;

    &:hover {
      color: $color-white;
    }

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.buy-trade-form-balance-picker {
  #{$trade-form-balance-picker}__btn {
    &:hover {
      background-color: $color-olivine;
    }
  }

  &--dark {
    #{$trade-form-balance-picker}__btn {
      background-color: $color-shuttle-gray;
      &:hover {
        background-color: $color-dingley;
      }
    }
  }
}

.sell-trade-form-balance-picker {
  #{$trade-form-balance-picker}__btn {
    &:hover {
      background-color: $color-coral;
    }
  }

  &--dark {
    #{$trade-form-balance-picker}__btn {
      background-color: $color-shuttle-gray;
    }
  }
}
