.faq {
  $root: &;
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;

  &--dark {
    #{$root}__questions-label {
      color: $color-white;
    }

    #{$root}__question {
      color: #ecf1ff;
      border-color: #444752;
    }

    #{$root}__desk-answer-question {
      color: $color-white;
    }

    #{$root}__desk-answer {
      p {
        color: #ecf1ff;
      }

      .bg {
        fill: $color-white;
      }

      .q {
        fill: #35383d;
      }
    }

    #{$root}__step {
      color: $color-white;
    }
  }

  &__title {
    width: 100%;
  }

  &__questions {
    max-width: 300px;
    margin-right: 100px;
  }

  &__questions-label {
    font-size: rems(16px);
    font-weight: 700;
    color: #363b49;
    margin-bottom: 5px;
    margin-top: 56px;
  }

  &__question {
    color: #64666d;
    border-top: 1px solid #e0e2e5;
    padding: 3px 0;
    cursor: pointer;
  }

  &__desk-answers {
    margin-top: 56px;
    max-width: 720px;
    flex: 1;
  }

  &__desk-answer {
    p {
      margin: 0;
      font-size: rems(14px);
      color: #64666d;
      line-height: rems(22px);
    }
  }

  &__desk-answer-question {
    font-weight: 700;
    color: #363b49;
    margin-left: 36px;
  }

  &__step {
    font-size: rems(14px);
    font-weight: 700;
    color: #64666d;
    margin-left: 56px;
  }
}

.faq-mobile {
  &__questions {
    margin-left: 36px;
  }

  &__plus {
    &:after {
      position: absolute;
      content: "";
      width: 2px;
      height: 16px;
      background-color: #64666D;
      top: 6px;
      left: -27px;
    }

    &:before {
      position: absolute;
      content: "";
      height: 2px;
      width: 16px;
      background-color: #64666D;
      top: 13px;
      left: -34px;
    }
  }

  &__radio {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .faq__desk-answer {
    display: none;
    font-weight: 400;
  }

  &__radio:checked ~ .faq__desk-answer {
    display: block;
  }

  &__radio:checked ~ &__plus {
    display: none;
  }

  .faq__step {
    margin-left: 0;
  }

  .faq__question {
    margin: 0;
    font-size: rems(16px);
    font-weight: 700;
    position: relative;
  }

  .faq__title {
    margin-bottom: 30px;
    margin-top: -20px;
  }
}

.faq-mobile.faq--dark {
  .faq__question {
    color: $color-white;
  }

  .faq__step {
    color: #bec4d2;
  }

  .faq__desk-answer {
    color: #bec4d2;
  }

  .faq-mobile__plus {
    &:after,
    &:before {
      background-color: #989898;
    }
  }
}
