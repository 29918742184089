* {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  display: flex;
}

html,
body {
  //height: 100%;
  width: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
}

#root {
  height: 100%;
  min-height: 100%;
}

div {
  box-sizing: border-box;
}

.main-container {
  $root: &;

  color: $color-gravel;
  fill: $color-gravel;
  background-color: $color-white;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &--dark {
    color: $color-white;
    fill: $color-white;
    background-color: $color-ebony;

    #{$root}__route-container {
      background-color: $color-vulcan;
    }
  }

  &__route-container {
    background-color: $color-solitude;
  }

  &__route {
    max-width: 1600px;
    padding: 0 20px;
    margin: 0 auto;
    //display: flex;
    //justify-content: center;
  }
}

@media all and (max-width: $mobileMax) {
  .main-container {
    padding-top: 72px;
  }
}
