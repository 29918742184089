@import "../../styles/styles-old/colors.module";
@import "../../styles/styles-old/abstract/functions";
@import "../../styles/styles-old/abstract/variables";

.footer {
  border-top: 6px solid $main;
  color: #868686;
  background-color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  svg {
    transition: all 150ms;
    fill: #9b9b9b;
  }

  .innerIcon {
    transition: all 150ms;
    fill: #fff;
  }
}

.footerDark {
  border-color: #424347;
  color: #64666d;
  background-color: #313338;

  svg {
    fill: #838a9b;
  }

  .innerIcon {
    fill: #313338;
  }

  .zeroX {
    fill: none;

    .innerIcon {
      fill: #838a9b;
    }
  }
}

.footerTitle {
  display: flex;
  justify-content: center;
}

.footerTitleText {
  background-color: $main;
  padding: 2px 11px 6px;
  color: white;
  margin: auto;
  border-radius: 0 0 0.5rem 0.5rem;
  font-size: rems(13px);
  font-weight: 700;
  cursor: default;
}

.footerTitleTextDark {
  background-color: #424347;
  color: #64666d;
  transition: all 150ms;

  &:hover{
    color: $main;
  }
}

.content {
  padding: 50px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: map_get($grid-breakpoints, "md")) {
    width: 100%;
  }
}

.contentLine {
  width: 100%;
  margin-top: 54px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 36px;
    left: 36px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);

    @media (max-width: map_get($grid-breakpoints, "md")) {
      right: 0;
      left: 0;
    }
  }
}

.contentLineDark {
  &:after {
    background-color: rgba(#9b9b9b, 0.15);
  }
}

.contentBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 36px;
  font-size: rems(16px);
  font-weight: 800;
  line-height: 1.1;
  color: #64666d;
  transition: all 150ms;
  cursor: default;

  &:link {
    text-decoration: none;
    cursor: pointer;
  }

  &:hover {
    color: $color-midnight-express;

    .contentSubTitle {
      color: $color-midnight-express;
    }

    svg {
      fill: $color-midnight-express;
    }
  }

  @media (max-width: map_get($grid-breakpoints, "md")) {
    flex-direction: column;
    align-items: start;
    padding: 0 24px;
    font-size: rems(12px);

    &:first-child {
      padding-left: 0;
    }

    &:nth-last-child(2) {
      padding-right: 0;
    }
  }

  @media (max-width: map_get($grid-breakpoints, "sm")) {
    padding: 0 12px;
  }
}

.contentBlockDark {
  color: #686e7d;

  &:hover {
    color: $color-white;

    .contentSubTitle {
      color: $color-white;
    }

    svg {
      fill: $color-white;
    }

    .zeroX {
      fill: transparent;

      .innerIcon {
        fill: $color-white;
      }
    }
  }
}

.contentImage {
  padding-right: 10px;

  @media (max-width: map_get($grid-breakpoints, "md")) {
    padding-bottom: 6px;
  }
}

.contentMainTitle {
  font-size: rems(15px);
  font-weight: 700;
  line-height: 1.1;
  color: #64666d;
  text-decoration: none;
  display: block;

  &:hover {
    color: #64666d;
    text-decoration: none;
  }
}

.contentMainTitleDark {
  color: #686e7d;

  &:hover {
    color: #686e7d;
  }
}

.contentSubTitle {
  transition: all 150ms;
  text-transform: uppercase;
  color: #9b9b9b;
  font-size: rems(10px);
  font-weight: 900;
  line-height: 1.1;

  @media (max-width: map_get($grid-breakpoints, "md")) {
    font-weight: 800;
  }
}

.contentSubTitleDark {
  color: #5a5f6a;
}

.copyright {
  text-align: center;
  padding: 35px 20px;
  font-size: rems(12px);
  color: #9b9b9b;
  max-width: 1920px;
  width: 100%;

  a {
    color: #9b9b9b;
    text-decoration: none;

    &:hover {
      color: #9b9b9b;
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 3px;
    line-height: 16px;
  }
}

.copyrightDark {
  //border-color: rgba(#9b9b9b, 0.15);
  color: #838a9b;
}

.link {
  color: $color-royal-blue;
  cursor: pointer;
  transition: color 150ms;

  &:hover {
    color: $color-black;
  }
}

.linkDark {
  color: $color-royal-blue;
  &:hover {
    color: $color-white;
  }
}

.mdHide {
  @media (max-width: map_get($grid-breakpoints, "md")) {
    display: none;
  }
}
