.tokens-table {
  height: 274px !important;

  @media (max-width: map_get($grid-breakpoints, "md")) {
    height: 344px !important;
  }

  &__cell{
    cursor: pointer;
    &--selected{
      background-color: rgba(#2a9ff8, 0.4);
      color: $color-black;
    }
  }


  &__star {
    cursor: pointer;
    font-size: rems(14px);
    color: $color-aluminium;

    &--selected {
      color: $main;
    }
  }

  &__toggle-fav-label {
    border: none;
    background-color: transparent;
    color: $color-storm-gray;
    font-size: rems(12px);
    font-weight: 700;
    margin: 0 8px;
  }

  &__toggle-fav-icon {
    font-size: rems(14px);
    margin-left: 8px;

    &--show {
      color: $main;
    }
  }
}

.virtualized-table--dark .tokens-table{
  &__cell{
    &--selected{
      color: $color-white;
      background-color: rgba(#4a90e2, 0.5);
    }
  }
}
