.transfer-modal {
  $root: &;
  max-width: 700px;
  padding: 70px 66px 48px 66px;

  &__transfer-btn {
    margin-left: 24px;
  }

  &__notice {
    color: $color-gray;
    margin-bottom: 28px;

    &--bold {
      font-weight: 700;
    }
  }

  &__input {
    margin-bottom: 36px;
  }

  &__fee {
    width: 100%;
    color: $color-gray;
    font-size: rems(11px);
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  &__input-radio {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__radio {
    position: relative;
    width: 20px;
    max-width: 20px;
    height: 20px;
    max-height: 20px;
    border-radius: 50%;
    background-color: $color-mischka-light;

    &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $color-white;
    }
  }

  &__input-radio:checked + &__radio:after {
    background-color: $color-dodger-blue-dark;
    border: 2px solid $color-white;
  }

  &__input-radio:checked ~ &__radio-label {
    color: $color-black-russian;
  }

  &__radio-label {
    font-size: rems(15px);
    color: $color-mischka-light;
    font-weight: 700;
    margin-left: 8px;
  }

  &__radio-group {
    cursor: pointer;

    &:first-child {
      margin-right: 66px;
    }
  }

  .wallet-modal__header {
    margin-bottom: 36px;
  }

  .trade-form-input__label,
  .trade-form-spread__label {
    color: $color-gray;
  }

  .trade-form-input__input {
    color: $color-black-russian;
  }

  .trade-form-input__input,
  .trade-form-input__button {
    border-color: $color-aqua-squeeze;
    background-color: transparent;
  }

  .trade-form-input__button:hover {
    border-color: $color-dodger-blue-dark;
    background-color: $color-dodger-blue-dark;

    &:after,
    &:before {
      background-color: $color-white;
    }
  }

  .trade-form-input__button:before,
  .trade-form-input__button:after {
    background-color: $color-shuttle-gray;
  }

  .trade-form-balance-picker__btn {
    background-color: rgba($color-mischka-light, 0.5);
    color: $color-aluminium;

    &:hover {
      background-color: $color-dodger-blue-dark;
      color: $color-white;
    }
  }

  &--dark {
    #{$root}__notice {
      color: $color-white;
    }

    #{$root}__radio-label {
      color: $color-shuttle-gray;
    }

    #{$root}__input-radio:checked ~ #{$root}__radio-label {
      color: $color-white;
    }

    #{$root}__fee {
      color: $color-aluminium;
    }

    #{$root}__radio {
      background-color: $color-black-russian-light;

      &:after {
        background-color: $color-tuna-blue;
        border: 1px solid $color-black-russian-light;
      }
    }

    #{$root}__input-radio:checked + #{$root}__radio:after {
      background-color: $color-dodger-blue-dark;
      border: 1px solid $color-black-russian-light;
    }

    #{$root}__input-radio:checked ~ #{$root}__radio-label {
      color: $color-white;
    }

    .trade-form-input__label,
    .trade-form-spread__label {
      color: $color-shuttle-gray;
    }

    .trade-form-input__input,
    .trade-form-input__button {
      border-color: $color-black-russian-light;
      background-color: $color-black-russian-light;
    }

    .trade-form-balance-picker__btn {
      background-color: rgba($color-shuttle-gray, 0.5);
      color: $color-aluminium;
    }

    .trade-form-input__input {
      color: $color-white;
    }
  }

  @media (max-width: $mobileMax) {
    padding: 60px 16px 28px 16px;

    &__fee {
      text-align: center;
    }

    &__modes {
      justify-content: center;
    }

    .button {
      flex: 1;
      max-width: initial;

      &:last-child {
        margin-left: 16px;
      }
    }
  }
}
