.switch {
  $root: &;
  display: flex;
  align-items: center;
  margin: 0;

  &__label {
    font-size: rems(12px);
    font-weight: 600;
    color: #1e1e19;
    margin-right: 10px;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__track {
    width: 46px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 13px;
    border: 1px solid #e1e6e2;
    transition: background-color 0.4s ease;
    cursor: pointer;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      width: 25px;
      height: 15px;
      border: none;
    }
  }

  &__control {
    width: 18px;
    height: 18px;
    background-color: #C8C8C9;
    border: 1px solid #e1e6e2;
    border-radius: 50%;
    transform: translateX(3px);
    transition: all 0.4s ease;
    position: absolute;
    pointer-events: none;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      width: 11px;
      height: 11px;
      border: none;
      transform: translateX(2px);
    }
  }

  &__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked ~ #{$root}__track {
      background-color: #4a90e2;
      border-color: #e1e6e2;
    }

    &:checked ~ #{$root}__control {
      transform: translateX(25px);
      background-color: #ffffff;
      border-color: #e1e6e2;

      @media (max-width: map_get($grid-breakpoints, "sm")) {
        transform: translateX(12px);
      }
    }
  }

  &--dark {
    #{$root}__label {
      color: #adb7d0;
    }

    #{$root}__track {
      border: none;
      background-color: #2B2C31;
    }

    #{$root}__control{
      background-color: #424347;
      border-color: #2B2C31;
    }
  }
}
