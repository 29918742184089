.base-modal {
  $root: &;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#000000, 0.65);
  color: #7d7c7c;
  z-index: 1000;
  padding: 20px;

  &--dark {
    background-color: rgba(#000000, 0.6);
    color: #ecf1ff;

    #{$root}__content {
      background-color: #2b2c31;
      box-shadow: 0 2px 48px rgba(#000000, 0.78);
    }
  }

  &--fullscreen {
    @media (max-width: map_get($grid-breakpoints, "sm")) {
      padding: 0;
    }

    #{$root}__content {
      @media (max-width: map_get($grid-breakpoints, "sm")) {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
  }

  &__content {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 48px rgba(#373a41, 0.42);
  }

  @media (max-width: $mobileMax) {
    padding: 0;

    &__content{
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }
}
