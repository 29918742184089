@import "abstract/functions";

.select {
  $root: &;
  position: relative;
  display: flex;
  align-items: center;

  &--dark {
    #{$root}__control {
      color: #bec4d2;
      background-color: #2b2c31;
      border: none;

      &:disabled {
        color: #64666d;
      }
    }

    #{$root}__icon {
      fill: #bec4d2;
    }

    #{$root}__separator {
      background-color: #35383d;
    }
  }

  &__control {
    font-size: rems(14px);
    font-weight: 600;
    height: 28px;
    border-radius: 14px;
    border: 1px solid #e1e6e2;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 36px;
    padding-left: 12px;
    color: #1e1e19;
    outline: none;
    background-color: #fff;

    &:disabled {
      color: #bab9bb;
    }

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      font-size: rems(16px);
    }
  }

  &__icon {
    color: #1e1e19;
    pointer-events: none;
    position: absolute;
    right: 12px;
  }

  &__separator {
    position: absolute;
    top: 0;
    right: 28px;
    bottom: 0;
    width: 1px;
    background-color: #e1e6e2;
  }
}

.select__control:disabled ~ .select__icon {
  fill: #bab9bb;
}

.select--dark .select__control:disabled ~ .select__icon {
  fill: #64666d;
}
