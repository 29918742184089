.transaction-link-cell {
  color: $color-royal-blue;
  font-weight: 700;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:link,
  &:hover {
    color: $color-royal-blue;
  }
}
