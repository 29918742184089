.unlisted-token-status-cell{
  width: 100%;
  max-width: 80px;
  font-size: rems(9px);
  font-weight: 700;
  border: none;
  padding: 2px 6px;
  border-radius: 2px;
  background-color: #3DBFFB;
  transition: background-color 150ms;

  &:hover{
    background-color: rgba(61, 191, 251, 0.65);
  }

  &:disabled{
    background-color: transparent;
    font-size: rems(10px);
  }

  &--dark{
    background-color: #3DBFFB;
    color: #fff;
  }
}
