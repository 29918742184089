@import "../../../styles/styles-old/colors.module";

@keyframes anim-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes anim-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.alertBox {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 2px;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
}

.alertMessage {
  position: relative;
  color: $white;
  font-weight: 700;
  background-color: #23262f;
  padding: 16px;
  border-radius: 6px;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.alertMessageInfo {
  background-color: #23262f;
}

.alertMessageInfoDark {
  color: #23262f;
  background-color: $white;
}

.alertMessageSuccess {
  background-color: #9bb45f;
}

.alertMessageError {
  background-color: #fe7c57;
}

.alertMessageShow {
  animation: anim-show 250ms forwards;
}

.alertMessageHide {
  animation: anim-hide 1000ms forwards;
}
