.licence-modal {
  $root: &;
  padding: 20px;

  .custom-modal {
    &__content-container {
      padding: 20px;
      max-height: unset;
      height: auto;
      width: auto;
      min-width: 280px;
      min-height: 280px;
    }

    &__close {
      top: 22px;
      right: 22px;
    }
  }

  .button {
    margin: 16% auto 0 auto;
  }

  &__img-container {
    padding: 1px;
  }

  &__img {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: block;
    min-height: 240px;
  }

  &--dark {
    #{$root}__img-container {
      background-color: transparent;
    }
  }

  @media (min-width: $mobileMax) {
    .button {
      display: none;
    }

    &__img-container {
      clip-path: polygon(
        calc(100% - 50px) 0,
        100% 50px,
        100% 100%,
        0 100%,
        0 0
      );
      background-color: $color-mischka-light;
    }

    &__img {
      clip-path: polygon(
        calc(100% - 50px) 0,
        100% 50px,
        100% 100%,
        0 100%,
        0 0
      );
    }
  }

  @media (max-width: $mobileMax) {
    &.custom-modal{
      background-color: $color-white;

      &--dark {
        background-color: $color-bastille;
      }
    }

    .custom-modal {
      &__close {
        display: none;
      }

      &__content-container {
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
}
