.row-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.col-md-6-15 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.col-md-5-15 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.col-md-7-15 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.col-md-6-45 {
  padding-left: 22.5px;
  padding-right: 22.5px;
}

.col-md-4-45 {
  padding-left: 22.5px;
  padding-right: 22.5px;
}

.col-md--45 {
  padding-left: 22.5px;
  padding-right: 22.5px;
}

.col-md--45 {
  padding-left: 22.5px;
  padding-right: 22.5px;
}

//New
.row-45 {
  margin-left: -22.5px;
  margin-right: -22.5px;
}

.col-45 {
  padding-left: 22.5px;
  padding-right: 22.5px;
}

.row-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.col-20 {
  padding-left: 10px;
  padding-right: 10px;
}

.row-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.col-15 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
