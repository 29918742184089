.order-book-chart {
  .recharts-text.recharts-cartesian-axis-tick-value {
    fill: #dcdcdc;
  }

  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-axis-line {
    stroke: #dcdcdc;
  }

  .recharts-tooltip-wrapper {
    background-color: transparent !important;
  }

  .recharts-default-tooltip {
    background-color: #f5f7f8 !important;
    border-color: #e1e6e2;
    padding: 2px 7px !important;
  }

  &--dark {
    .recharts-text.recharts-cartesian-axis-tick-value {
      fill: #64666d;
    }

    .recharts-cartesian-axis-tick-line,
    .recharts-cartesian-axis-line {
      stroke: #64666d;
    }

    .recharts-default-tooltip {
      background-color: #35383d !important;
      border-color: transparent !important;

      li {
        font-size: rems(14px) !important;
      }
    }
  }
}
