.loader,
.loader:after {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.loader {
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #ffffff;
  transform: translateZ(0);
  animation: load 1.1s infinite linear;

  &--dark{
    border-color: rgba($color-merlin, 0.22);
    border-left-color: $color-merlin;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
