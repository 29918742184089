$trade-form-spread: ".trade-form-spread";

#{$trade-form-spread} {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__label {
    width: 100%;
    font-size: rems(12px);
    font-weight: 700;
    color: $color-shuttle-gray;
    margin-bottom: 3px;
  }

  &__slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 42px;
    border-radius: 4px;
    border: 1px solid $color-gray-nurse;
    padding: 0 20px;
  }

  &__range {
    display: block;
    font-size: rems(10px);
    line-height: rems(14px);
    font-weight: 800;
    margin-top: 3px;
    color: $color-aluminium;
  }

  .rangeslider {
    margin: 0;
    height: 4px;
    border-radius: 2px;
    background-color: rgba($color-tuna-blue, 0.15);
  }

  .rangeslider-horizontal {
    width: 100%;
  }

  .rangeslider-horizontal .rangeslider__handle {
    width: 18px;
    height: 18px;
  }

  .rangeslider-horizontal .rangeslider__fill {
    height: 4px;
  }
}

.buy-trade-form-spread {
  #{$trade-form-spread}__slider {
    background-color: $twilightBlue;
  }

  .rangeslider .rangeslider__handle {
    background-color: $color-olivine;
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: $color-olivine;
  }

  &--dark {
    #{$trade-form-spread}__slider {
      background-color: rgba($color-black, 0.35);
      border: none;
    }

    .rangeslider {
      background-color: $color-black;
    }

    .rangeslider .rangeslider__handle {
      background-color: $color-dingley;
    }

    .rangeslider-horizontal .rangeslider__fill {
      background-color: $color-dingley;
    }
  }
}

.sell-trade-form-spread {
  #{$trade-form-spread}__slider {
    background-color: $color-seashell;
    border-color: $color-bizarre;
  }

  .rangeslider .rangeslider__handle {
    background-color: $color-coral;
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: $color-coral;
  }

  &--dark {
    #{$trade-form-spread}__slider {
      background-color: rgba($color-black, 0.35);
      border: none;
    }

    .rangeslider {
      background-color: $color-black;
    }
  }
}
