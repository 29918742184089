.button {
  color: $color-white;
  font-size: rems(12px);
  font-weight: 700;
  padding: 0 12px;
  outline: none;
  border: none;
  border-radius: 4px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--primary {
    background-color: $color-royal-blue;
  }

  &--secondary {
    background-color: $color-shamrock;
  }

  &--link {
    text-align: center;

    &:link {
      text-decoration: none;
    }

    &:hover {
      color: $color-white;
    }
  }

  &--w-175 {
    width: 100%;
    max-width: 175px;
  }

  &--w-140 {
    width: 100%;
    max-width: 140px;
  }

  &--w-234 {
    width: 100%;
    max-width: 234px;
  }
}

.custom-button {
  height: 40px;
  padding: 0 0 0 rems(14px);
  border: none;
  border-radius: 4px;
  background-color: #40c69f;
  font-size: rems(12px);
  font-weight: 700;
  line-height: rems(16px);
  transition: all 150ms;
  display: flex;
  align-items: center;

  &:link {
    text-decoration: none;
  }

  &:hover {
    background-color: #30a381;
  }

  &__icon-container {
    padding-right: 14px;
    margin-right: 14px;
    border-right: 1px solid #fff;
    height: 40px;
    display: flex;
    align-items: center;
    opacity: 0.5;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      margin-right: 0;
      border-right: none;
    }
  }

  &__title {
    color: #fff;
    margin-right: rems(14px);

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      display: none;
    }
  }
}
