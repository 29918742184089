.api-doc {
  $root: &;
  display: flex;

  &__header {
    margin: 50px 0 54px 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      margin: 20px 0 20px 0;
    }
  }

  &__page-title {
    font-size: rems(36px);
    line-height: rems(49px);
    font-weight: 600;
    margin: 0;
  }

  &__titles {
    margin: 0 rems(120px) 0 0;
    list-style: none;

    @media (max-width: map_get($grid-breakpoints, "lg")) {
      margin-right: rems(44px);
    }

    @media (max-width: map_get($grid-breakpoints, "md")) {
      display: none;
    }
  }

  &__list-title {
    font-weight: 600;
    line-height: rems(22px);
    color: #1e1e19;
    white-space: nowrap;
    margin-bottom: 6px;
    transition: all 150ms;
    display: flex;
    align-items: center;

    &:link {
      text-decoration: none;
    }

    &:hover {
      color: #4a90e2;
    }
  }

  &__arrow {
    transform: rotate(-90deg);
    margin-right: 10px;
  }

  &__chapter-title {
    color: #1e1e19;
    font-weight: 700;
    font-size: 1.2rem;
    margin: rems(34px) 0 0 0;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      margin-top: rems(20px);
    }
  }

  &__chapter-text {
    color: #9b9b9b;
    font-size: rems(14px);
    line-height: rems(22px);
    margin: 0;

    &--api {
      color: #1e1e19;
      font-family: "Courier Prime", monospace;

      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
      }
    }

    &--subtitle {
      margin-top: rems(28px);
    }

    &--no-margin {
      margin: 0;
    }
  }

  &__list {
    padding: 0 0 0 rems(34px);

    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  &__chapters {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin: rems(-34px) 0 rems(50px) 0;

    @media (max-width: map_get($grid-breakpoints, "sm")) {
      margin-top: rems(-20px);
    }
  }

  &__chapter {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &--dark {
    #{$root}__list-title {
      color: #adb7d0;

      &:hover {
        color: #4a90e2;
      }
    }

    #{$root}__chapter-title {
      color: #ecf1ff;
    }

    #{$root}__chapter-text {
      color: #ecf1ff;

      &--api {
        color: #adb7d0;
      }
    }
  }
}
